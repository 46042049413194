<template>
  <el-dialog :visible.sync="visible" :title="form && form.id ? '编辑收货地址信息' : '新增收货地址信息'" append-to-body width="640px" @closed="form = null">
    <el-form ref="form" :model="form" :rules="rules" label-position="right" label-width="84px" label-suffix=":" v-if="form">
      <div class="h c">
        <el-form-item label="收货地址" prop="provinceCode">
          <quick-select v-model="form.provinceCode" :label.sync="form.provinceName" url="api/regionalDict/province" value-field="code" placeholder="选择省份" filterable style="width: 180px;" @change="form.cityCode = null; form.districtCode = null;" />
        </el-form-item>
        <el-form-item label-width="12px" prop="cityCode" style="width: 180px;">
          <quick-select v-model="form.cityCode" :label.sync="form.cityName" :url="`api/regionalDict/parent/${form.provinceCode}`" value-field="code" placeholder="选择城市" filterable v-if="form.provinceCode" style="width: 100%;" @change="form.districtCode = null" />
          <el-input disabled placeholder="请先选择省份" v-else />
        </el-form-item>
        <el-form-item label-width="12px" prop="districtCode" style="width: 180px;">
          <quick-select v-model="form.districtCode" :label.sync="form.districtName" :url="`api/regionalDict/parent/${form.cityCode}`" value-field="code" placeholder="选择地区" filterable v-if="form.cityCode" style="width: 100%;" />
          <el-input disabled placeholder="请先选择城市" v-else />
        </el-form-item>
      </div>
      <el-form-item prop="address">
        <el-input v-model="form.address" :maxlength="100" class="flex" placeholder="请输入详细地址" />
      </el-form-item>
      <el-form-item label="设为默认">
        <div class="h c">
          <el-switch v-model="form.isDefault" />
          <div class="padding-0-10 fc-g">提示:&nbsp;开启后，每次下单将默认使用该收货地址</div>
        </div>
      </el-form-item>
    </el-form>

    <div slot="footer">
      <el-button type="text" @click="visible = false">取消</el-button>
      <el-button :loading="saving" type="primary" @click="doSave">保存</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { addAddress as add, editAddress as edit } from "@/api/receiving";

export default {
  data() {
    return {
      visible: false,
      form: null,
      saving: false,
      rules: {
        provinceCode: [{ required: true, message: "请选择省份" }],
        cityCode: [{ required: true, message: "请选择城市" }],
        districtCode: [{ required: true, message: "请选择地区" }],
        address: [{ required: true, message: "请填写详细地址" }]
      }
    };
  },
  methods: {
    doSave() {
      this.$refs.form &&
        this.$refs.form.validate().then(_ => {
          this.saving = true;
          (this.form.id ? edit : add)(this.form)
            .then(res => {
              this.$notify({
                title: `保存收货地址信息成功`,
                type: "success",
                duration: 2500
              });
              this.$parent.init();
              this.visible = false;
            })
            .finally(_ => {
              this.saving = false;
            });
        });
    },
    resetForm(form) {
      this.form = form || {
        provinceCode: null,
        provinceName: "",
        cityCode: null,
        cityName: "",
        districtCode: null,
        districtName: "",
        address: "",
        isDefault: false
      };
      this.visible = true;
    }
  }
};
</script>