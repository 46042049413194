<template>
  <div>
    <el-button class="filter-item" type="primary" icon="el-icon-plus" @click="add">新增收货地址</el-button>
    <el-table v-loading="loading" row-key="id" :data="data" size="small" stripe style="margin-top: 10px;">
      <el-table-column prop="provinceName" label="省" min-width="120" />
      <el-table-column prop="cityName" label="市" min-width="150" />
      <el-table-column prop="districtName" label="区" min-width="150" />
      <el-table-column prop="address" label="详细地址" min-width="320" />
      <el-table-column label="默认" width="100">
        <template slot-scope="scope">
          <i class="el-icon-check" v-if="scope.row.isDefault"></i>
        </template>
      </el-table-column>
      <el-table-column width="100" fixed="right">
        <div class="row-commands" slot-scope="scope">
          <el-button size="mini" type="text" @click="edit(scope.row)">编辑</el-button>
          <el-popover :ref="scope.row.id" placement="top" width="180">
            <p>确定删除本条数据吗？</p>
            <div style="text-align: right; margin: 0">
              <el-button size="mini" type="text" @click="$refs[scope.row.id].doClose()">取消</el-button>
              <el-button :loading="delLoading" type="primary" size="mini" @click="subDelete(scope.row.id)">确定</el-button>
            </div>
            <el-button slot="reference" class="danger" type="text" size="mini">删除</el-button>
          </el-popover>
        </div>
      </el-table-column>
    </el-table>
    <el-pagination :total="total" :current-page="page + 1" style="margin-top: 8px;" layout="total, prev, pager, next, sizes" @size-change="sizeChange" @current-change="pageChange" />

    <e-form ref="form" />
  </div>
</template>

<script>
import checkPermission from "@/utils/permission";
import initData from "@/mixins/initData";
import eForm from "./address-form";
import { delAddress as del } from "@/api/receiving";

export default {
  components: { eForm },
  mixins: [initData],
  created() {
    this.init();
  },
  methods: {
    checkPermission,
    beforeInit() {
      this.url = "api/distributor/address";
      this.params = { page: this.page, size: this.size };
      return true;
    },
    add() {
      this.$refs.form && this.$refs.form.resetForm();
    },
    edit(data) {
      this.$refs.form &&
        this.$refs.form.resetForm(JSON.parse(JSON.stringify(data)));
    },
    subDelete(id) {
      this.delLoading = true;
      del(id)
        .then(res => {
          this.delLoading = false;
          this.$refs[id].doClose();
          this.dleChangePage();
          this.init();
          this.$notify({
            title: "删除成功",
            type: "success",
            duration: 2500
          });
        })
        .catch(err => {
          this.delLoading = false;
          console.log(err.response.data.message);
        });
    }
  }
};
</script>