<template>
  <div class="app-container">
    <el-tabs>
      <el-tab-pane label="联系人">
        <e-contact />
      </el-tab-pane>
      <el-tab-pane label="收货地址">
        <e-address />
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import eContact from "./contact";
import eAddress from "./address";

export default {
  components: { eContact, eAddress }
};
</script>